import React from "react";
import './Edition2014.css';
import seconde from '../../assets/Invitation/2eme.jpg';
import Caroussel2014 from "../../components/caroussels/carousselU/caroussels/caroussel2014/Caroussel2014";

const Edition2014 = () => {

    return (
        <div className="Edition 2014">
            <h2>Edition 2014</h2>

            <div>
                <h3 className="H2014">Seconde Edition</h3>
                    <div className="hero2014">
                        <p>
                        Du 29 mai au 2 juin 2014, la capitale des Comores, Moroni, a vibré au rythme de la créativité et de l'inspiration lors de la seconde édition du Festival d'Arts Contemporains des Comores (FACC). Cet événement a rassemblé de nombreux festivaliers venus des quatre coins du monde, désireux de partager leur talent et leur savoir-faire avec le public comorien.<br/>
                        Le festival s'est distingué par la diversité des disciplines artistiques présentées. Les festivaliers ont pu admirer des performances de danse envoûtantes, des sculptures innovantes, des peintures captivantes, ainsi que des œuvres de vidéo et de photographie. Les créations de mode et les projets scientifiques ont également trouvé leur place, offrant un panorama riche et surprenant de la scène artistique contemporaine.<br/>
                        Une des initiatives phares de cette édition, intitulée <b>formes, signes et symboles</b>, a permis de déplacer les productions artistiques vers différents lieux de la cité. Cette démarche a non seulement enrichi l'expérience des festivaliers, mais a également rendu l'art plus accessible à la population locale, favorisant ainsi une interaction directe entre les artistes et les habitants.<br/>
                        En somme, la seconde édition du FACC a été un véritable succès, contribuant à dynamiser la vie culturelle des Comores et à renforcer les liens entre les artistes locaux et internationaux. Ce festival a non seulement célébré l'art sous toutes ses formes, mais a également joué un rôle essentiel dans la promotion de la culture comorienne sur la scène mondiale.
                        </p>
                        <img src={seconde} alt="invitation au second volet" className="Sec" />
                    </div>
            </div>

            <div className="M">
                <h3 className="Ma">Présentation de la marraine</h3>
                <div className="maraine">
                    <p>
                    Sakina M'Sa a joué un rôle essentiel en tant que marraine de la seconde édition du Festival d'Arts Contemporains des Comores (FACC) en 2014.<br/>
                    En succédant au sculpteur sénégalais Ousmane Sow, elle a apporté une visibilité internationale au festival grâce à sa reconnaissance en tant que créatrice de mode. <br/>
                    Née à Nioumadzaha, Sakina a des racines comoriennes, ce qui renforce son engagement envers la culture et l'art des Comores. Son implication a permis de mettre en avant l'importance de la mode dans le paysage artistique contemporain des Comores, en lien avec le thème du festival, "Hudjijuwa", qui signifie "la connaissance de soi". <br/>
                    En tant que marraine, elle a également facilité les échanges entre les artistes comoriens et internationaux, contribuant ainsi au développement culturel de l'archipel. Son rôle a été déterminant pour promouvoir la richesse artistique des Comores et pour renforcer les liens entre la diaspora comorienne et son pays d'origine. 
                    </p>
                    <div className="sakina">
                        <iframe 
                            width="695" 
                            height="391" 
                            src="https://www.youtube.com/embed/RLRavL0nNQc" 
                            title="Pharrell Williams : dans sa version féministe ? - À l'Affiche!" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
            </div>

            <div className="A">
                <h3> Dimension Artistique</h3>
                <div className="dimArt">
                    <div className="greg">
                        <iframe 
                            width="695" 
                            height="391" 
                            src="https://www.youtube.com/embed/N_P5kbkEAfY" 
                            title="Arts martiaux d'Afrique et d'Outre-mer" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen>
                        </iframe>
                    </div>
                    <p>
                    Les objectifs du FACC incluaient la valorisation de la création artistique locale, la promotion des artistes comoriens, le renforcement des liens entre créateurs de la région, et la sensibilisation des jeunes générations à l'art contemporain.<br/>
                    Le festival intégrait également un volet cinéma en partenariat avec le Comoros International Film Festival (CIFF), proposant des projections en lien avec le thème du festival.<br/>
                    Un aspect scientifique était aussi présent, avec des forums et des expositions abordant l'histoire et la culture comoriennes sous différents angles (sociologie, archéologie, anthropologie).
                    Des tables rondes étaient organisées pour discuter de la place de l'artiste dans le pays et de la création régionale dans le contexte de l'art contemporain mondial.<br/>
                    Enfin, la présence de la marraine Sakina M'sa mettait en lumière la création de mode, un domaine artistique peu valorisé localement.
                    </p>
                    <div className="fat">
                        <iframe 
                            width="695" 
                            height="391" 
                            src="https://www.youtube.com/embed/f7O7cGdElJs" 
                            title="TV5 Monde - Festival d'Arts Contemporains des Comores 2014" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
            </div>

            <div className="S">
                <h3 className="sc"> Pédagogie</h3>
                <div className="scol2024">
                    <p>
                        Le Festival d'Arts Contemporains des Comores (FACC) visait à attirer un large public, tant international que national, en mettant en avant le dynamisme créatif de l'archipel, dont près de 60 % de la population a moins de 25 ans. <br/>
                        Le festival cherchait à établir des liens entre le monde scolaire, l'univers artistique et la pensée scientifique à travers des actions pédagogiques organisées avec les enseignants. Plusieurs mois avant le festival, les élèves des collèges impliqués travaillaient sur le concept du festival avec leurs enseignants d'arts plastiques et d'histoire. Des interventions d'artistes en milieu scolaire étaient encouragées pour accompagner les élèves dans leurs projets de création.<br/>
                        Cette initiative offrait aux jeunes l'opportunité de découvrir l'art, d'explorer leurs émotions et de mieux comprendre la démarche créative des artistes. Le processus se concluait par des conférences et des expositions à partir de mars 2014, où les travaux réalisés étaient regroupés lors des "Manifestations de Territoires". Un concours désignait des lauréats qui accompagnaient les artistes à Moroni, avec un concours final organisé à l'Université des Comores.<br/>
                        Ainsi, le FACC visait à impliquer activement la jeunesse comorienne dans le processus artistique et à enrichir leur compréhension de leur patrimoine culturel et historique.
                    </p>
                    <Caroussel2014 />
                </div>
            </div>

            <div className="Par">
                <h3>Partenariat avec le CIFF</h3>
                <div className="Partenariat">
                    <p>
                        Le CIFF – Comoros International Film Festival – fut le premier festival de cinéma des Comores. Initié en 2011 par une association du même nom, il œuvra également au sein du FACC lors de sa première édition en juin 2012.<br/>
                        La promotion et le développement des arts constituèrent le fil conducteur de l’action ainsi menée, et une étroite coopération s’installa naturellement entre le FACC et le CIFF.<br/>
                        Les ambitions du CIFF visaient à montrer ce que le cinéma pouvait offrir comme signes et outils de compréhension du monde. Initier le regard, faire découvrir à la population ce jeune cinéma, et mettre en place une plateforme pour former les aspirants réalisateurs du pays constituaient les principaux objectifs de cet important dispositif. Le CIFF proposa à nouveau la projection de films en rapport avec le thème du Festival.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Edition2014;
